import React from 'react';
import Checkout from '../components/checkout';

import Layout from '../components/layout'


const Membership = (props) => {      
    
    return (
      <Layout>     
          <article>
          <header>
            <h1 itemProp="headline">Premium Membership</h1>

            </header>
            <div className="pt-5"> 
                If you would like to use Mathcurious FREE resources for commercial purposes like private online teaching, Outschool etc., you need to obtain a special license. 
                Mathcurious Premium membership grants this license. This Premium membership only costs <b>$10 per month</b>, and you can cancel it anytime.             
            </div>
            <div>
                You are not allowed to copy, redistribute or resell any of these resources or their derivates. Other terms of use listed at <a href="https://mathcurious.com/terms-of-use">https://mathcurious.com/terms-of-use</a> still apply.
            </div>
            <div className="pt-5">
                <Checkout />
            </div>
            <div className="pt-5">

            </div>
            <div className="pt-5">
                
            </div>
        </article>

        
      </Layout>
    );  
}
export default Membership;

