import React, { useState } from "react"
import { loadStripe } from "@stripe/stripe-js"
import axios from 'axios'

const buttonStyles = {
  fontSize: "13px",
  textAlign: "center",
  color: "#000",
  padding: "12px 60px",
  boxShadow: "2px 5px 10px rgba(0,0,0,.1)",
  backgroundColor: "rgb(255, 178, 56)",
  borderRadius: "6px",
  letterSpacing: "1.5px",
}

const buttonDisabledStyles = {
  opacity: "0.5",
  cursor: "not-allowed",
}

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY)
  }
  return stripePromise
}

const Checkout = () => {
  const [loading, setLoading] = useState(false)

  const redirectToCheckout = async event => {
    event.preventDefault()
    setLoading(true)

    const res = await axios.post(`https://api.mathcurious.com/create_checkout_session`, {});        
                
    if (res.statusText === 'OK') {
        const stripe = await getStripe()
        const { error } = await stripe.redirectToCheckout({
            sessionId: res.data.sessionId 
        })
        if (error) {
            console.warn("Error:", error)
            setLoading(false)
        }    
    } 
  }

  return (
    <button
      disabled ={loading}
      style={
        loading ? { ...buttonStyles, ...buttonDisabledStyles } : buttonStyles
      }
      onClick={redirectToCheckout}
    >
      Buy Premium Membership
    </button>
  )
}

export default Checkout